import React from 'react'
import { Container } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container style={{marginTop: '12rem',marginBottom: '12rem', color: 'dimgrey'}}>
      <h1>404</h1>
      <h1>Požadovaná stránka nebola nájdená.</h1>
    </Container>
  </Layout>
)

export default NotFoundPage
